import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import { FaCheckCircle,FaTimesCircle  } from "react-icons/fa";

const PaymentStatus = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const statusType = queryParameters.get("status")
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.href = "account-statement";
          }, 2000);
      
          return () => clearTimeout(timeout);
       
      }, []);
    
  return (
    <LayoutNew>
      <div class="top-class">
      <div class="container">
      {statusType=="success" &&
        <div class="row justify-content-center">
            <div class="col-md-5">
                <div class="message-box _success">
                    <FaCheckCircle/>
                    <h2> Your payment was successful </h2>
                   <p> Thank you for your payment. we will <br/>
                       be in contact with more details shortly </p>      
            </div> 
        </div> 
    </div> }
 
  
    {statusType=="failed" &&
  <div class="row justify-content-center">
            <div class="col-md-5">
                <div class="message-box _success _failed">
                    <FaTimesCircle />
                    <h2> Your payment failed </h2>
             <p>  Try again later </p> 
         
            </div> 
        </div> 
    </div> 
}
    </div> 
      </div>
    </LayoutNew>
  );
};

export default PaymentStatus;
