import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import bkashLogo from "../assets/images/loginlogo.webp";
import bajifairLogo from "../assets/images/logo-bajifair.png";

import rightArrow from "../assets/images/right-arrow.png";
import { Controller, useForm } from "react-hook-form";
import { Form, Button, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { pick, isEmpty } from "lodash";
import jwt_decode from "jwt-decode";
import { IoIosArrowBack } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";

const Login = () => {
  const { sendOTP, setBr, lang, setEn, setUser } = useContext(AuthContext);
  const { t } = useTranslation();
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      countryCode: 91,
      uniqueId: Math.random() * 10000,
    },
  });
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  let onSubmit = async (body) => {
    setLoader(true);
    const timeoutId = setTimeout(() => {
      window.location.reload();
    }, 30000);
    if (parseInt(body.validateCode) === parseInt(getValidateCode)) {
      const { status, data } = await apiPost(
        apiPath.loginUser,
        pick(body, ["username", "password", "uniqueId"])
      );

      if (status === 200) {
        if (data.success) {
          clearTimeout(timeoutId);
          setTimeout(() => {
            navigate("/");
            window.location.reload();
          }, 500);
          setLoader(false);
          localStorage.setItem("token", data.results?.token);
          localStorage.setItem("refresh_token", data.results?.refresh_token);
          setUser(jwt_decode(data?.results?.token));
          navigate("/");
        } else {
          clearTimeout(timeoutId);
          setLoader(false);
          toast.error(data?.message);
        }
      } else {
        clearTimeout(timeoutId);
        setLoader(false);
        toast.error(data?.message);
      }
    }
  };
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [getValidateCode, setValidateCode] = useState("");

  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 110px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 10, 116);
    setValidateCode(code);
  };
  useEffect(() => {
    changeCode();
  }, []);
  return (
    <>
      <div className="">
        {/* <LoginSlider /> */}
        <div className="login-form active slide-right">
          <div className="back-header menu-header ">
            <IoIosArrowBack
              size={22}
              style={{ paddingLeft: "0px", width: "10%" }}
              onClick={() => navigate("/")}
              color="#fffc"
            />
            <p style={{ width: "55%" }} className=" d-flex">
              {t("Login")}
            </p>
          </div>
          <div className="login-image">
            <p>
              <span>প্রতারণার হাত থেকে বাঁচতে</span> সবার আগে
              <br />
              এজেন্টকে ভেরিফাই করুন <a href="#">
                KHELMU.com
              </a>{" "}
              ভিজিট করে ।
            </p>
            <img
              style={{ width: "100%" }}
              src={bajifairLogo}
              alt=""
              
            />
            <p className="subtext">আপনার সকল অভিযোগ জানাতে কিংবা যেকোনো সমস্যায় মেসেজ দিন</p>
            <a href="https://khelmu.com/support" className="wabtn"> <FaWhatsapp/>+19784445407</a>
          </div>

          
          <div className="reg-data">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-2 reg-logo-login">
                <Form.Group
                  className="d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <Form.Label>{t("Username")}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("username", {
                      required: "Please enter Username",
                    })}
                    placeholder="4-15 char, allow numbers, no space"
                  />
                </Form.Group>
                {errors.username && errors.username?.message && (
                  <div className="text-danger">{errors.username.message}</div>
                )}
                <Form.Group
                  className="d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <Form.Label> Password</Form.Label>
                  <div style={{ position: "relative", width: `100%` }}>
                    <Form.Control
                      type={passwordType ? passwordType : "password"}
                      placeholder="6-20 characters or numbers"
                      {...register("password", {
                        required: "Please enter password",
                        minLength: {
                          value: 4,
                          message:
                            "Password should contain atleast 8 characters",
                        },
                        maxLength: {
                          value: 15,
                          message:
                            "Password should contain maximum 16 characters",
                        },
                      })}
                    />
                    <div
                      // className="input-group-btn"
                      style={{
                        position: "absolute",
                        right: "2%",
                        top: "22%",
                        // bottom: "0px",
                        // filter: `invert(1)`,
                      }}
                    >
                      <span
                        className="btn btn-outline-primary"
                        onClick={togglePassword}
                        style={{
                          backgroundColor: "transparent",
                          border: "0",
                          padding: "8px",
                          paddingTop: "3px",
                          height: "72%",
                        }}
                      >
                        {passwordType === "password" ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </span>
                    </div>
                  </div>
                </Form.Group>
                {errors.password && errors.password.message && (
                  <div className="text-danger">{errors.password.message}</div>
                )}
                <Form.Group
                  className="d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <Form.Label className="">{"Verification code"}</Form.Label>

                  <div className="validationcode-div d-flex justify-content-between">
                    <input
                      type="number"
                      keyboardType="numeric"
                      autocomplete="off"
                      maxLength="4"
                      className={"form-control"}
                      {...register("validateCode", {
                        required: "Please enter validate code",
                        validate: {
                          validate: (value) =>
                            parseInt(value) === parseInt(getValidateCode) ||
                            "Invalid validate code",
                        },
                      })}
                      onChange={(e) => {
                        if (e.target.value.length == 4) {
                          e.target.blur();
                          unregister("validateCode", { keepValue: true });
                        }
                      }}
                      id="validCode"
                      placeholder="Validation Code"
                    />
                    <canvas
                      ref={canvasRef}
                      onClick={changeCode}
                      className="inputcanvas"
                      id="authenticateImage"
                    />
                  </div>
                </Form.Group>

                {errors.validateCode && errors.validateCode.message && (
                  <label className="text-danger text-left">
                    {errors.validateCode.message}
                  </label>
                )}
              </div>

              <button type="submit" className="submit-btn" disabled={isLoader}>
                <span> {isLoader ? "Loading..." : "Login"}</span>
              </button>
              {/* <div className="term-condtion">
                <p>
                  {t("Do_not_have_an_account")}
                  <Link to="/register">{t("Sign_up")}</Link>
                </p>
              </div> */}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
