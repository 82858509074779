import React, { useContext,useState ,useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import FlagIcon from "../assets/imagesNew/united-states.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";

const LanguagePopup = () => {
    let {setShowLanguage,lang, setEn, setBr} = useContext(AuthContext);

  const {t} = useTranslation()
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowLanguage(false);
      }}
    >
      
    
      <div className="member-menu-content slide-right header-language-section">
        <p className="c-lang">Currency and Language</p>
            <button
             className="bg-transparent border-0 p-0 close-b"
             onClick={() => setShowLanguage(false)}
           >
             <RxCross2 />
           </button>
       
           <ul>
        
    
        
          <li className="header-language-select">
            <div>
          <img src={lang == "bn" ? FlagIconBn :   FlagIconBn} alt="" />{" "}
          <span> ৳  BDT </span>
          </div>
          <div className="d-flex justify-content-between"> 

          <div className={lang == "en"?"pop-language-select-div active":"pop-language-select-div"} onClick={() => {setEn(); }}>English</div>
          <div className={lang == "bn"?"pop-language-select-div active":"pop-language-select-div"}  onClick={() => {setBr(); }} > বাংলা </div>
         
          </div>
        </li>
        
       
        
        </ul>
         
          
         </div>
     
    
      
    </OutsideClickHandler>
  );
};

export default LanguagePopup;
