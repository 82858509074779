import { find, isEmpty } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import BackLayHome from "./BackLayHome";
import LiveMatchTracker from "../pages/Score/ScoreBoard";
import AuthContext from "../context/AuthContext";
import { Accordion, Button, Row } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import growth from "../assets/images/growth.svg";
import BetSlipContext from "../context/BetSlipContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { SportAccordianFavourite } from "./SportAccordianFavourite";
import useScroll from "./Scroll";
import { FavouriteDisplayMatch } from "./FavouriteDisplayMatch";
// import SportBookInner from "./SportBookInner";
// import SportBookInnerFav from "./SportBookInnerFav";
const SportAccordianInnerFav = ({
  matchOdds,
  object,
  keyTime,
  index,
  addToFavioute,
  activeFav,
  setActiveFav,
  matchBookmaker,
  matchFancy,
  matchSportBook,
  ids,
  position,
}) => {
  const { user, setShowPosition } = useContext(AuthContext);
  const navigate = useNavigate();
  const naviagte = useNavigate();
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [scrollHeight, setScrollHeight] = useState(75);
  const [runner, setRunner] = useState([]);
  const [select, setSelect] = useState({});
  const [bookmakerObject, setBookmakerObject] = useState({});
  const [fancyList, setFancyList] = useState([]);
  const [bookmaker, setBookmaker] = useState([]);
  const [selected, setSelected] = useState("");
  const [marketNew, setMarketNew] = useState([]);
  const [sportBook, setSportBook] = useState([]);
  const [getPosition, setPosition] = useState([]);
  const {
    setBetSlipObject,
    setShowBetDialog,
    showBetDialog,
    betSlipObject,
    resetBetPosition,
    resetBetSlipObject,
    setResetBetPosition,
    betPlace,
  } = useContext(BetSlipContext);

  useEffect(() => {
    setRunner(object?.Odds);
    if (object?.Bookmaker?.length > 0) {
      setBookmaker(object?.Bookmaker[0]?.jsonBookmakerData);
      setBookmakerObject(object?.Bookmaker[0]);
    }
  }, []);

  useEffect(() => {
    setPosition(position);
    if (position?.betFair?.length > 0) {
      setRunner((prev) => {
        return prev?.map((res) => {
          return {
            ...res,
            runners: res?.runners?.map((item) => {
              let find = position?.betFair?.find((obj) => {
                return obj?.selectionId == item?.SelectionId;
              });
              if (!isEmpty(find)) {
                return { ...item, position: find?.position };
              } else {
                return item;
              }
            }),
          };
        });
      });
    }
    if (position?.bookmaker?.length > 0) {
      setBookmaker((prev) => {
        return prev?.map((item) => {
          let find = position?.bookmaker?.find((obj) => {
            return obj?.selectionId == item?.selectionID;
          });
          if (!isEmpty(find)) {
            return { ...item, position: find?.position };
          } else {
            return item;
          }
        });
      });
    }
  }, [position]);

  useEffect(() => {
    if (matchFancy?.length > 0) {
      setFancyList(
        object?.Fancy?.map((res) => {
          let obj = matchFancy?.find((item) => {
            return item?.selectionId == res?.selectionId;
          });
          if (!isEmpty(obj)) {
            return {
              ...res,
              odds: !isEmpty(obj) ? obj : {},
              ...obj,
              fancyName: res.name,
            };
          } else {
            return { ...res, name: res?.fancyName };
          }
        })
      );
    }
  }, [matchFancy]);

  useEffect(() => {
    if (matchSportBook?.length > 0 || object?.SportBook?.length > 0) {
      setSportBook(
        object?.SportBook?.map((res) => {
          let obj =
            matchSportBook?.length > 0
              ? matchSportBook?.find((item) => {
                  return item?.id == res?.selectionId;
                })
              : {};
          if (isEmpty(obj)) {
            return {
              ...res,
              name: res.fancyName,
              marketName: res?.fancyName,
            };
          } else {
            return {
              ...res,
              odds: !isEmpty(obj) ? obj : {},
              ...obj,
              fancyName: res.name,
            };
          }
        })
      );
    }
  }, [matchSportBook, object?.SportBook]);

  const handelChange = (odds, type, item, object, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setScrollId(index);
        setShowBetDialog(true);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: item,
          betType: type,
          playType: "betFair",
          object: {
            ...object,
            ...odds,
            teamName: item,
            eventId: object?.eventId,
            marketId: object?.marketId,
          },
        }));
      }
    } else {
      navigate("/login");
    }
  };

  const handelChangeBookmaker = (odds, type, item, object, index, res) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setScrollId(index);
        setShowBetDialog(true);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: item,
          betType: type,
          playType: "bookmaker",
          object: {
            ...object,
            ...odds,
            teamName: item,
            eventId: object?.eventId,
            marketId: object?.bookmakerMarketId?.substring(1),
            SelectionId: res?.selectionID?.substring(1),
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };
  const handelChangeSportBook = (odds, item, res, index) => {
    if (!isEmpty(user)) {
      if (odds > 0) {
        setScrollId(index);
        setShowBetDialog(true);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds,
          eventId: object?.eventId,
          marketId: object?.marketId,
          teamName: res?.selectionName,
          item: { ...item },
          playType: "sportBook",
          object: {
            ...res,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  const handelChangeFancy = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setScrollId(index);
        setShowBetDialog(true);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: team,
          betType: type,
          fancyName: team,
          playType: "fancy",
          object: {
            ...item,
            ...odds,
            fancyName: team,
            teamName: team,
            eventId: object?.eventId,
            marketId: object?.marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  const handelBetFairPositions = (slipObj, runner) => {
    let updatedArray = [...runner]?.find((res) => {
      return res?.marketId == slipObj?.object?.marketId;
    })?.runners;
    let selectionsTemp = updatedArray;

    if (selectionsTemp?.length > 0) {
      if (betSlipObject?.bidPrice > 0) {
        if (slipObj?.object?.ib) {
          let backProfit = Number(
            Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice)
          );
          let backLoss = Number(betSlipObject?.bidPrice);
          if (Math.abs(selectionsTemp[0]?.position) > 0) {
            selectionsTemp = selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            });
          } else {
            selectionsTemp = selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            });
          }
        } else {
          let backProfit = Number(betSlipObject?.bidPrice);
          let backLoss =
            Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
          if (Math.abs(selectionsTemp[0]?.position) > 0) {
            selectionsTemp = selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            });
          } else {
            selectionsTemp = selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            });
          }
        }
      }

      setRunner((prev) => {
        return prev?.map((res) => {
          if (res?.marketId == slipObj?.object?.marketId) {
            return {
              ...res,
              runners: selectionsTemp,
            };
          } else {
            return res;
          }
        });
      });
    }
  };

  const handelSportBookPositions = (slipObj) => {
    slipObj = betSlipObject;
    if (slipObj?.bidPrice >= 0) {
      let backProfit = (slipObj.odds - 1) * slipObj?.bidPrice;
      let backLoss = slipObj?.bidPrice;
      setSportBook((current) => {
        return current?.map((rl) =>
          rl?.id == slipObj?.object?.marketId
            ? {
                ...rl,
                sportsBookSelection: rl?.sportsBookSelection?.map((res) => {
                  return res.id === slipObj?.object?.id
                    ? {
                        ...res,
                        position:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? res.position
                            : 0,
                        newPosition:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? Number(backProfit) + Number(res.position)
                            : Number(backProfit),
                      }
                    : {
                        ...res,
                        position:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? res.position
                            : 0,
                        newPosition:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? Number(res.position) - Number(backLoss)
                            : -Number(backLoss),
                      };
                }),
              }
            : rl
        );
      });
    }
  };

  const handelBookmakerPositions = (slipObj) => {
    let selectionsTemp = [...bookmaker]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    if (slipObj?.bidPrice > 0) {
      if (slipObj?.object.ib) {
        let backProfit = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
        let backLoss = Number(slipObj?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.runnerName == slipObj?.teamName) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - Number(backLoss),
                };
              }
            })
          );
        } else {
          let backProfit = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
          let backLoss = Number(slipObj?.bidPrice);

          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.runnerName == slipObj?.teamName) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          let backProfit = Number(slipObj?.bidPrice);
          let backLoss = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.runnerName == slipObj?.teamName) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + Number(backProfit),
                };
              }
            })
          );
        } else {
          let backProfit = Number(slipObj?.bidPrice);
          let backLoss = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.runnerName == slipObj?.teamName) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };
  const handelBetFairPositionsMulti = (slipObj) => {
    let newselection = marketNew?.find((res) => {
      return res?.mi == slipObj?.mi;
    })?.jsonData;
    let selectionsTemp = [...newselection];
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          selectionsTemp = selectionsTemp?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            }
          });
        } else {
          selectionsTemp = selectionsTemp?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            } else {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            }
          });
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          selectionsTemp = selectionsTemp?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            }
          });
        } else {
          selectionsTemp = selectionsTemp?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            } else {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            }
          });
        }
      }
    }
    setMarketNew((res) => {
      return res?.map((item) => {
        if (item?.mi == slipObj?.mi) {
          return { ...item, jsonData: selectionsTemp };
        } else {
          return item;
        }
      });
    });
  };

  const resetPosition = (type) => {
    if (type == "betFair") {
      setRunner((current) =>
        current?.map((res) => {
          return {
            ...res,
            newPosition: 0,
          };
        })
      );
    } else if (type == "bookmaker") {
      setBookmaker((current) =>
        current?.map((res) => {
          return {
            ...res,
            newPosition: 0,
          };
        })
      );
    } else if (type == "multi") {
      setMarketNew((current) =>
        current?.map((res) => {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                newPosition: 0,
              };
            }),
          };
        })
      );
    }
    setResetBetPosition(false);
    resetBetSlipObject();
  };
  useEffect(() => {
    if (betPlace?.isPlaced && betPlace?.type == "error") {
      resetPosition(betPlace?.betType);
    }
  }, [betPlace]);

  useEffect(() => {
    if (resetBetPosition) {
      resetPosition(betSlipObject?.playType);
    }
  }, [resetBetPosition]);

  useEffect(() => {
    if (showBetDialog) {
      if (!isEmpty(betSlipObject)) {
        if (betSlipObject?.playType == "betFair") {
          handelBetFairPositions(betSlipObject, runner);
        } else if (betSlipObject?.playType == "fancy") {
          // handelFancyPositions(betSlipObject);
        } else if (betSlipObject?.playType == "sportBook") {
          handelSportBookPositions(betSlipObject);
        } else if (betSlipObject?.playType == "bookmaker") {
          handelBookmakerPositions(betSlipObject);
        } else if (betSlipObject?.playType == "multi") {
          handelBetFairPositionsMulti(betSlipObject);
        }
      }
    }
  }, [betSlipObject, showBetDialog]);

  useEffect(() => {
    let header = document.getElementById("header")
      ? Number(document.getElementById("header")?.offsetHeight) + 2
      : 0;
    setScrollHeight(header);
  }, []);
  return (
    <div
      className="sport-accord-body bg-white"
      style={{ borderRadius: "5px", marginBottom: "10px" }}
    >
      <div className="position-relative">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ background: "#064b4d",color:`#fff` }}
          onClick={() =>
            navigate(`/full-market/${object?.marketId}/${object?.eventId}`)
          }
        >
          <div
            style={{
              fontSize: ".9285714286rem",
              lineHeight: "1.25",
              fontWeight: "700",
              paddingLeft: "10px",
            }}
          >
            <span>{object?.eventName}</span>
          </div>
          <div className="d-flex align-items-center">
            <Button
              style={{ marginRight: "10px" }}
              className="bg-transparent text-dark border-0 px-2 pt-0 pe-0"
            >
              <FaAngleRight />
            </Button>
          </div>
        </div>
        {runner?.length > 0 &&
          runner?.map((res) => {
            let oddsInner =
              matchOdds?.length > 0
                ? matchOdds?.find((item) => {
                    return item?.mi == res?.marketId;
                  })
                : {};
            return (
              <SportAccordianFavourite
                label={res?.market}
                labelShow={"Match Odds"}
                data={res}
                addToFavioute={addToFavioute}
                totalMatched={res?.totalMatched}
              >
                <FavouriteDisplayMatch
                  runner={res?.runners}
                  matchOdds={oddsInner}
                  totalMatched={res?.totalMatched}
                  object={object}
                  res={res}
                  type="betfair"
                  betSlipObject={betSlipObject}
                  index={index}
                  position={
                    position?.betFair?.length > 0 ? position?.betFair : []
                  }
                  handelChange={handelChange}
                  addToFavioute={addToFavioute}
                  setSelect={setSelect}
                  scrollId={scrollId}
                  myRef={myRef}
                  scrollHeight={scrollHeight}
                />
              </SportAccordianFavourite>
            );
          })}{" "}
        {/* {bookmaker?.length > 0 && (
          <SportAccordianFavourite
            labelShow={"Bookmaker"}
            addToFavioute={addToFavioute}
            label={"Bookmaker"}
            totalMatched={0}
            data={bookmakerObject}
          >
            <FavouriteDisplayMatch
              runner={bookmaker}
              type="bookmaker"
              matchOdds={
                matchBookmaker?.length > 0
                  ? matchBookmaker?.find((item) => {
                      return (
                        `B${item?.mi}` == bookmakerObject?.bookmakerMarketId
                      );
                    })
                  : {}
              }
              position={
                position?.bookmaker?.length > 0 ? position?.bookmaker : []
              }
              object={object}
              betSlipObject={betSlipObject}
              index={index}
              res={bookmakerObject}
              handelChange={handelChangeBookmaker}
              setSelect={setSelect}
              scrollId={scrollId}
              myRef={myRef}
              scrollHeight={scrollHeight}
            />
          </SportAccordianFavourite>
        )}{" "}
        {sportBook?.length > 0 && (
          <SportAccordianFavourite
            labelShow={"SportBook"}
            label={"SportBook"}
            totalMatched={0}
          >
            <div className={`sport-listing`}>
              {sportBook?.map((item, index) => {
                let condition =
                  item?.marketStatus == 9 ||
                  item?.marketStatus == 2 ||
                  item?.marketStatus == 3 ||
                  item?.marketStatus == 4;
                return (
                  <Accordion defaultActiveKey={[0, 1, 2, 3, 4]}>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header
                        style={{ scrollMarginTop: scrollHeight }}
                        ref={index == scrollId ? myRef : null}
                      >
                        <div
                          onClick={() =>
                            addToFavioute(
                              object?.eventId,
                              "SportBook",
                              item?.id || item?.selectionId
                            )
                          }
                          style={{ width: "3.8rem", background: "" }}
                          className={`fave  bg-star-sprit-black  start-0 ${
                            true ? "animate" : ""
                          }`}
                        ></div>

                        {item?.marketName}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <div className="sports-listing-score">
                          <Row
                            className={`gx-1 ${
                              item?.apiSiteStatus !== "ACTIVE"
                                ? "sports-book-disabled"
                                : condition
                                ? "sports-book-disabled"
                                : ""
                            }`}
                          >
                            {(item?.apiSiteStatus !== "ACTIVE" ||
                              condition) && (
                              <strong className="sports-book-disabled-text">
                                {item?.apiSiteStatus !== "ACTIVE"
                                  ? "Suspended"
                                  : item?.marketStatus == 9
                                  ? "Ball Running"
                                  : item?.marketStatus == 2
                                  ? "In Active"
                                  : item?.marketStatus == 3
                                  ? "Suspended"
                                  : item?.marketStatus == 4
                                  ? "Closed"
                                  : "Suspended"}
                              </strong>
                            )}
                            {item?.sportsBookSelection?.map((res) => {
                              let newPosition = "";
                              let findPosition =
                                position?.fancyAndPremium?.find((obj) => {
                                  return (
                                    Number(obj?.selectionId) ==
                                      Number(res?.marketId) &&
                                    obj?.fancyName == res?.selectionName
                                  );
                                });
                              let oldPosition = !isEmpty(findPosition)
                                ? findPosition?.position
                                : "";
                              if (
                                showBetDialog &&
                                betSlipObject?.playType == "sportBook" &&
                                item?.id == selected
                              ) {
                                let backProfit =
                                  (Number(betSlipObject?.odds) - 1) *
                                  Number(betSlipObject?.bidPrice);
                                let backLoss = Number(betSlipObject?.bidPrice);
                                if (res?.id == betSlipObject?.object?.id) {
                                  newPosition =
                                    Math.abs(oldPosition) > 0
                                      ? Number(backProfit) + Number(oldPosition)
                                      : Number(backProfit);
                                } else {
                                  newPosition =
                                    Math.abs(oldPosition) > 0
                                      ? Number(backLoss) - Number(oldPosition)
                                      : -Number(backLoss);
                                }
                              }
                              let activeClass =
                                betSlipObject?.teamName == res?.selectionName &&
                                betSlipObject?.odds == res?.odds &&
                                item?.marketName ==
                                  betSlipObject?.item?.marketName &&
                                showBetDialog
                                  ? "back-active"
                                  : "";

                              let conditionPosition =
                                showBetDialog &&
                                betSlipObject?.playType == "sportBook" &&
                                item?.id == selected &&
                                Number(betSlipObject?.bidPrice) > 0
                                  ? true
                                  : false;
                              let oldObject =
                                item?.oldSportsBookSelection?.find((obj) => {
                                  return obj?.id == res?.id;
                                });
                              return (
                                <SportBookInnerFav
                                  oldObject={oldObject}
                                  res={res}
                                  handelChange={handelChangeSportBook}
                                  item={item}
                                  position={
                                    position?.fancyAndPremium?.length > 0
                                      ? position?.fancyAndPremium
                                      : []
                                  }
                                  oldPosition={oldPosition}
                                  index={index}
                                  parly={false}
                                  activeClass={activeClass}
                                  newPosition={newPosition}
                                  conditionPosition={conditionPosition}
                                  setSelected={setSelected}
                                  selectSpark={[]}
                                />
                              );
                            })}
                          </Row>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </div>
          </SportAccordianFavourite>
        )}{" "}
        {fancyList?.length > 0 && (
          <SportAccordianFavourite label={"Fancy"} totalMatched={0}>
            {" "}
            <div className="match-odd-table bg-white py-1 px-0 mobile-match">
              {fancyList?.length > 0 && (
                <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
                  <span className="text-center w-100">No</span>
                  <span className="text-center w-100">Yes</span>
                </div>
              )}
              <ul className="mb-2 rounded fancy-list position-relative p-1">
                {fancyList?.length > 0 ? (
                  fancyList?.map((item, index) => {
                    let find = position?.fancyAndPremium?.find((obj) => {
                      return (
                        Number(obj?.selectionId) == Number(item?.selectionId)
                      );
                    });
                    let oldPosition = !isEmpty(find) ? find?.position : 0;
                    let newPosition = "";
                    if (
                      betSlipObject?.teamName === item?.name &&
                      showBetDialog
                    ) {
                      if (betSlipObject?.object?.ib) {
                        if (Math.abs(oldPosition) > 0) {
                          let backLoss =
                            Number(betSlipObject?.bidPrice) +
                            Number(oldPosition);
                          newPosition = backLoss;
                        } else {
                          let backLoss = Number(betSlipObject?.bidPrice);
                          newPosition = backLoss;
                        }
                      } else {
                        if (Math.abs(oldPosition) > 0) {
                          let backProfit =
                            (Number(betSlipObject?.bidPrice) *
                              Number(betSlipObject?.object?.pt)) /
                            100;
                          newPosition = backProfit + Number(oldPosition);
                        } else {
                          let backProfit =
                            (Number(betSlipObject?.bidPrice) *
                              Number(betSlipObject?.object?.pt)) /
                            100;
                          newPosition = backProfit;
                        }
                      }
                    }
                    let condition =
                      betSlipObject?.teamName === item?.name &&
                      Math.abs(newPosition) > 0;

                    return (
                      <>
                        <li
                          style={{
                            scrollMarginTop: scrollHeight,
                          }}
                          ref={index == scrollId ? myRef : null}
                          className="position-relative fancy-li top-list-move"
                        >
                          <div className="fancy-list-first">
                            <span
                              onClick={() =>
                                addToFavioute(
                                  object?.eventId,
                                  "Fancy",
                                  item?.selectionId,
                                  object?.eventType
                                )
                              }
                              style={{ width: "3.8rem" }}
                              className={`fave  bg-star-sprit-black  start-0 ${"animate"}`}
                            ></span>
                            <h2>
                              {item?.name}{" "}
                              {(Math?.abs(oldPosition) > 0 || condition) && (
                                <span
                                  className={`d-block py-1 ${"team-red-score"}`}
                                >
                                  {Math?.abs(oldPosition) > 0
                                    ? `( ${Math?.abs(oldPosition).toFixed(2)} )`
                                    : ""}
                                  {betSlipObject?.teamName === item?.name &&
                                    Math.abs(newPosition) > 0 && (
                                      <span>
                                        {" "}
                                        {">"}{" "}
                                        {`( ${Math.abs(newPosition).toFixed(
                                          2
                                        )} )`}
                                      </span>
                                    )}
                                </span>
                              )}
                            </h2>
                          </div>
                          {Math?.abs(oldPosition) > 0 && (
                            <span
                              onClick={() =>
                                setShowPosition({
                                  status: true,
                                  object: {
                                    ...item,
                                    match: object?.eventName,
                                  },
                                })
                              }
                              style={{
                                height: "100%",
                                fontSize: ".7857142857142857rem",
                                color: "white",
                                background: "rgb(111 136 152/1)",
                                padding: ".35714285714285715rem",
                                borderRadius: "4px",
                                marginRight: "5px",
                                fontWeight: "400",
                                alignSelf: "center",
                                alignContent: "end",
                              }}
                            >
                              Book
                            </span>
                          )}
                          {item?.odds?.rt?.length > 0 ? (
                            <div className="lay-back-table">
                              <div
                                title={
                                  item?.odds?.rt?.length > 0
                                    ? item?.odds?.rt[0]?.rt
                                    : ""
                                }
                                id={"lay_odds_fancy_" + index}
                                className={`lay ${
                                  Number(
                                    document.getElementById(
                                      "lay_odds_fancy_" + index
                                    )?.title
                                  ) !==
                                  item?.odds?.rt?.length > 0
                                    ? item?.odds?.rt[0]?.rt
                                    : 0
                                    ? "spark-lay"
                                    : ""
                                }   ${
                                  betSlipObject?.teamName == item?.name &&
                                  betSlipObject?.odds ==
                                    item?.odds?.rt[0]?.rt &&
                                  showBetDialog &&
                                  betSlipObject?.betType == "No"
                                    ? "lay-active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handelChangeFancy(
                                    item?.odds?.rt[0],
                                    "No",
                                    item?.name,
                                    item,
                                    index
                                  )
                                }
                              >
                                <strong>{item?.odds?.rt[0]?.rt}</strong>
                                <span className="d-block">
                                  {item?.odds?.rt[0]?.pt}
                                </span>
                              </div>
                              <div
                                title={
                                  item?.odds?.rt?.length > 0
                                    ? item?.odds?.rt[1]?.rt
                                    : ""
                                }
                                id={"back_odds_fancy_" + index}
                                className={`back ${
                                  Number(
                                    document.getElementById(
                                      "back_odds_fancy_" + index
                                    )?.title
                                  ) !== item?.odds?.rt[1]?.rt
                                    ? "spark-back"
                                    : ""
                                } 
                          ${
                            betSlipObject?.teamName == item?.name &&
                            betSlipObject?.odds == item?.odds?.rt[1]?.rt &&
                            showBetDialog &&
                            betSlipObject?.betType == "Yes"
                              ? "back-active"
                              : ""
                          }
                          `}
                                onClick={() =>
                                  handelChangeFancy(
                                    item?.odds?.rt[1],
                                    "Yes",
                                    item?.name,
                                    item,
                                    index
                                  )
                                }
                              >
                                <strong>{item?.odds?.rt[1]?.rt}</strong>
                                <span className="d-block">
                                  {item?.odds?.rt[1]?.pt}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="lay-back-table">
                              <div className={`lay`}>
                                <strong>0</strong>
                                <span className="d-block">0</span>
                              </div>
                              <div className={`back `}>
                                <strong>0</strong>
                                <span className="d-block">0</span>
                              </div>
                            </div>
                          )}
                          {!isEmpty(item?.odds) ? (
                            item?.odds?.ms == 9 ||
                            item?.odds?.ms == 2 ||
                            item?.odds?.ms == 3 ||
                            (item?.odds?.ms == 4 && (
                              <div class="overlay-match">
                                {item?.odds?.ms == 9
                                  ? "Ball Running"
                                  : item?.odds?.ms == 2
                                  ? "In Active"
                                  : item?.odds?.ms == 3
                                  ? "Suspended"
                                  : item?.odds?.ms == 4
                                  ? "Closed"
                                  : ""}
                              </div>
                            ))
                          ) : (
                            <div class="overlay-match">Suspended</div>
                          )}
                        </li>
                      </>
                    );
                  })
                ) : (
                  <div className="w-100 d-flex justify-content-start">
                    No Record Found
                  </div>
                )}
              </ul>
            </div>
          </SportAccordianFavourite>
        )}{" "} */}
      </div>
    </div>
  );
};

export default SportAccordianInnerFav;

// {
//   "eventId": "33557375",
//   "marketId": "1.232674034",
//   "centralizedId": "33557375S1767642",
//   "selectionId": 1767642,
//   "betPlaceTime": "2024-09-11, 5:25:26",
//   "bhav": 100,
//   "amount": "2",
//   "betType": "No",
//   "betRun": 4,
//   "betPlaceType": "fancy"
// }

// {
//   "fancyName": "10 OVER TOTAL ODD ZIMW",
//   "runnerName": "10 OVER TOTAL ODD ZIMW",
// }
