import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import BackLay from "./BackLay";
import AuthContext from "../context/AuthContext";
import { priceFormat } from "../Utils/constants";
import growth from "../assets/images/growth.svg";
import BattingIframe from "./BattingIframe";
import SportAccordianInner from "./SportAccordianInner";

const SportAccordian = ({
  item,
  index,
  keyTime,
  user,
  showOdds,
  activeKeys,
  handleSelect,
  setLimit,
  type,
  score,
  odds,
  setActiveFav,
  activeFav,
  gameTab,
  multiOddsPremium,
}) => {
  const [showScore, setShowScore] = useState([]);
const {blockList} = useContext(AuthContext)
  const addToFavioute = async (
    id,
    favoriteMarketId,
    favoriteType,
    eventType
  ) => {
    const { status, data: response_users } = await apiGet(
      apiPath.addToFavourite +
        `?eventId=${id}&favoriteMarketId=${favoriteMarketId}&favoriteType=${favoriteType}&eventType=${eventType}`
    );
  };
  const [scrollHeight, setScrollHeight] = useState(50);
  useEffect(() => {
    let header = document.getElementById("header")
      ? Number(document.getElementById("header")?.offsetHeight)
      : 0;
    setScrollHeight(header);
  }, []);

  return (
    <>
      <div key={index} className="bookmaker p-sm-2 p-1 sport-accordian">
        <Accordion
          activeKey={activeKeys}
          onSelect={(e) => handleSelect(e, type)}
        >
          <Accordion.Item eventKey={item?.seriesId}>
            <Accordion.Header>
              {" "}
              <div>
                <span> {item?.seriesName} </span>
                <span
                  className={
                    !activeKeys.includes(item?.seriesId)
                      ? "sport-counter bg-yellow"
                      : "sport-counter"
                  }
                >
                  {item?.matchList?.length}
                </span>
              </div>
              {!activeKeys.includes(item?.seriesId) ? (
                <i class="icon-arrow-down-sencodary"></i>
              ) : (
                <i
                  class="icon-arrow-down-sencodary"
                  style={{ rotate: `180deg` }}
                ></i>
              )}
            </Accordion.Header>
            <Accordion.Body className="p-0 py-1 px-sm-3 px-2">
              {item?.matchList
                ?.filter((el) => !el.eventName.includes(" T10 "))
                .map((object, index) => {
                  let matchOdds =
                    odds?.find((res) => {
                      return res?.mi == object?.marketId;
                    }) || {};
                  return (
                    <SportAccordianInner
                      showScore={showScore}
                      score={score}
                      index={index}
                      keyTime={keyTime}
                      block={blockList?.includes(object?.eventId) || false}
                      setActiveFav={setActiveFav}
                      activeFav={activeFav}
                      matchOdds={matchOdds}
                      object={object}
                      addToFavioute={addToFavioute}
                      setShowScore={setShowScore}
                      scrollHeight={scrollHeight}
                      gameTab={gameTab}
                      multiOddsPremium={multiOddsPremium}
                      type={type}
                    />
                  );
                })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default SportAccordian;
