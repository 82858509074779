import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import whatsapp from "../assets/images/icon-customer.svg";
import telegram from "../assets/images/telegram.png";
import livechat from "../assets/images/livechat.png";
import { RxCross2 } from "react-icons/rx";
import { isBrowser } from "react-device-detect";
import { isEmpty } from "lodash";
import Draggable from "react-draggable";
import Close from "../assets/images/icon_close.png";
import LeftIcon from "../assets/images/luck.png";
import casino1 from "../assets/images/Casino/casino1.webp";
import casino2 from "../assets/images/Casino/casino2.png";
import casino3 from "../assets/images/Casino/casino3.png";
import casino4 from "../assets/images/Casino/casino4.png";
import casino5 from "../assets/images/Casino/casino5.png";
import casino6 from "../assets/images/Casino/casino6.png";
import casino7 from "../assets/images/Casino/casino7.webp";
import casino8 from "../assets/images/Casino/casino8.webp";
import Slider from "react-slick";

const Footer = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 6,
    cssEase: "linear",
  };
  const location = useLocation();
  const { user, showSidebar, active, setFooterLink ,launchCasino, casinoUrl,
    setCasinoUrl,} = useContext(AuthContext);
  const navigate = useNavigate();
  const [casinoModel, setCasinoModel] = useState(false);
  const [showSupport, setShowSupport] = useState(true);
  const [icon, setIcon] = useState({});
  
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [touch, setTouch] = useState("");
  const handleTouchStart = (event) => {
    const touch = event?.touches[0];

    setStartX(touch?.clientX);
    setStartY(touch?.clientY);
  };

  const handleTouchEnd = (event) => {
    const touch = event.changedTouches[0];
    const endX = touch.clientX;
    const endY = touch.clientY;
    const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
    if (distance < 5) {
      setCasinoModel(true);
    }
  };

  
  const getData = async () => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    const { data } = await apiGet(apiPath?.getIcon, {
      website: "bajifair.live"
    });
    if (data?.success) {
      setIcon(data?.results);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  

  let drag = {
    top: -600,
    left: -250,
    right: 0,
    bottom: 25,
  };
  const games = [
    {
      id: 2,
      name: "Coin Toss",
      coins: 123,
      image: casino7,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-036",
        check: true,
      },
    },
    {
      id: 2,
      name: "european roulette",
      coins: 123,
      image: casino8,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-027",
        check: true,
      },
    },
    {
      id: 2,
      name: "Andar Bahar",
      coins: 123,
      image: casino1,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-032",
        check: true,
      },
    },
    {
      id: 1,
      name: "Fortune Flip",
      coins: 167,
      image: casino2,
      request: {
        platForm: "PP",
        gameType: "SLOT",
        casinoType: "PP-SLOT-217",
        check: true,
      },
    },
    {
      id: 5,
      name: "Beer",
      coins: 145,
      image: casino3,
      request: {
        platForm: "PP",
        gameType: "SLOT",
        casinoType: "PP-SLOT-271",
        check: true,
      },
    },
    {
      id: 3,
      name: "7up7down",
      coins: 145,
      image: casino4,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
        check: true,
      },
    },
    {
      id: 4,
      name: "Rocket",
      coins: 145,
      image: casino5,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-041",
        check: true,
      },
    },
    // {
    //   id: 4,
    //   name: "Rocket",
    //   coins: 145,
    //   image: casino6,
    //   request: {
    //     platForm: "KINGMAKER",
    //     gameType: "TABLE",
    //     casinoType: "KM-TABLE-041",
    //     check: true,
    //   },
    // },
  ];
  return (
    <>
       <div className="footer">
        {location?.pathname == "/" && !showSidebar && active == "sports" && (
          icon?.whatsappContent!== "" || icon?.telegramContent!== "" || icon?.livechatContent!== "" ?
          <div className="betbtn1">
            {icon?.whatsappContent && icon?.whatsappContent !== "" && showSupport && (
              <>
              <button
              className="bg-transparent border-0 p-0 close-b"
              onClick={() => setShowSupport(false)}
            >
              <RxCross2 />
            </button>
              <div
                onClick={() => {
                  window.open("https://wa.me/"+icon?.whatsappContent, "_self");
                }}
              >
                
       
                <img
                  src={icon?.whatsappIcon?process.env.REACT_APP_API_BASE_URL + icon?.whatsappIcon:whatsapp}
                  alt=""
                />
              </div>
              </>
            )}
            {/* {icon?.telegramContent && icon?.telegramContent !== "" && (
              <div
                onClick={() => {
                  
                  window.open(icon?.telegramContent, "_self");
                }}>
                <img
                  src={icon?.telegramIcon?process.env.REACT_APP_API_BASE_URL + icon?.telegramIcon:telegram}
                  alt=""
                />
              </div>
            )}{" "}
            {icon?.livechatContent && icon?.livechatContent !== "" && location?.pathname == "/" && (
              <div
                onClick={() => {
                  window.open(icon?.livechatContent, "_self");
                }}
              >
                <img
                  src={icon?.livechatIcon?process.env.REACT_APP_API_BASE_URL + icon?.livechatIcon:livechat}
                  alt=""
                />
              </div>
            )} */}
           
          {/*  {location?.pathname == "/" && (
              <noscript>
                <a
                  href="https://www.livechat.com/chat-with/17022978/"
                  rel="nofollow"
                >
                  Chat with us
                </a>
                , powered by{" "}
                <a
                  href="https://www.livechat.com/?welcome"
                  rel="noopener nofollow"
                  target="_blank"
                >
                  LiveChat
                </a>
              </noscript>
            )}*/}
          </div>
          :""
          
        )}
        {(location?.pathname == "/" || location?.pathname == "/sports") &&
          !showSidebar &&
          active == "sports" && (
            <>
             
              {isBrowser ? (
                <div
                  onClick={() => {
                    if (isEmpty(user)) {
                      navigate("/login");
                    } else {
                      setCasinoModel(true);
                    }
                  }}
                  style={{ position: isBrowser ? "absolute" : "fixed" }}
                  className="betbtn1"
                >
                  <img src="../../assets/images/entrance.svg" alt="" />
                </div>
              ) : (
                <Draggable
                  bounds={drag}
                  onStart={handleTouchStart}
                  onStop={handleTouchEnd}
                >
                  <div
                    onClick={() => {
                      if (isEmpty(user)) {
                        navigate("/login");
                      }
                    }}
                    style={{ position: isBrowser ? "absolute" : "fixed" }}
                    className="betbtn1"
                  >
                    <img src="../../assets/images/entrance.svg" alt="" />
                  </div>
                </Draggable>
              )}
               <div    style={{position:isBrowser ? 'absolute' : 'fixed'}} class="betbtn2">
              <div id="live-chat" class="icon-chat text-24 text-white" onClick={() => {
                window.open("https://khelmu.com/support", "_self");
              }}></div>
              </div> 
            </>
          )}
      </div> 
      {casinoModel && (
        <div className="lobby slide-up">
          <div className="gmnheader">
            {/* <div
              className="header__img d-flex justify-content-between align-items-center"
              style={{ height: "9vw" }}
            >
              <div style={{ fontSize: "15px", marginLeft: "10px" }}>
                Lucky09
              </div>
              <span className="header__number" data-v-302cf84b="">
                2
              </span>
            </div> */}
            <div className="position-relative w-100">
              {" "}
              <span className="position-relative">
                {" "}
                <img
                  style={{ width: "113px", marginTop: `6px` }}
                  src={LeftIcon}
                />{" "}
                <span className="luckspan">Luckco7</span>
              </span>
              {/* <span className="header__number" data-v-302cf84b="">
                5
              </span> */}
            </div>

            <div className="header__close" data-v-302cf84b="">
              <img
                className="icon-promote"
                src={Close}
                onClick={() => {
                  setCasinoModel(false);
                  setCasinoUrl("");
                }}
                alt=""
              />
            </div>
            
          </div>
          <div class="header-casino-footer w-full"></div>
          <ul
            class={
              !isEmpty(casinoUrl)
                ? "game-grid-new slide-left"
                : "game-grid slide-left"
            }
          >
            {!isEmpty(casinoUrl) ? (
              <div className="casino-play-inner">
                <iframe
                  style={{ minHeight: "100%" }}
                  className="w-100"
                  src={casinoUrl}
                />
              </div>
            ) : (
              <Slider
                className="w-100 h-100 parly-slider bottom-casino-slider"
                {...settings}
              >
                {games.map((game, index) => (
                  <div
                    id={game?.id}
                    onTouchStart={(e) => setTouch(e?.target?.id)}
                    onTouchEnd={() => setTouch("")}
                    onClick={() => launchCasino(game?.request)}
                    class={`game-card-casino slide-left ${
                      touch == game?.id ? "game-card-casino-new slide-left" : ""
                    }`}
                  >
                    {/* <div id={game?.id} class="coin-info">
                    <MdSupervisorAccount />
                    <span id={game?.id} class="coin-count">
                      167
                    </span>
                  </div> */}
                    <img
                      id={game?.id}
                      src={game?.image}
                      alt="Fortune Flip"
                      class="game-image"
                    />
                    {/* <div id={game?.id} class="game-info">
                    <h2 id={game?.id} class="game-title">
                      {game?.name}
                    </h2>
                  </div> */}
                  </div>
                ))}
              </Slider>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default Footer;
