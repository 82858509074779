import React, { useContext,useState ,useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Form, Button, InputGroup } from "react-bootstrap";
const Refercode = (props) => {
    let {lang, setEn, setBr} = useContext(AuthContext);
    const [isCopied, setCopied] = useState(false);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
  
      return () => clearTimeout(timeout);
    }, [isCopied]);
  const {t} = useTranslation()
  // console.log(props?.codeData.length);
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        props.setShowCode(false);
      }}
    >
      
    
      <div className="member-menu-content slide-right header-language-section">
        <p className="c-lang">Copy Refer Code</p>
            <button
             className="bg-transparent border-0 p-0 close-b"
             onClick={() => props.setShowCode(false)}
           >
             <RxCross2 />
           </button>
       
           <ul class="list-group" style={{padding:`10px`}}>
            {props?.codeData.length>0 && props?.codeData.map((item,index) => {
              return(   <li class="list-group-item active" key={index+1}>{item?.referalCode} 
                <CopyToClipboard
                text={item?.referalCode}
                onCopy={() => setCopied(true)}
              >
                <Button
                  className="copy-btn"
                  style={{
                    fontSize: `13px`,
                    padding: `5px`,
                    backgroundColor: `#005dac`,
                    color: `#fff`,
                  }}
                >
                  {isCopied ? "Copied!" : "Copy"}
                </Button>
              </CopyToClipboard>
              </li>)
            
             }) }
               
                          
  
</ul>
         
          
         </div>
     
    
      
    </OutsideClickHandler>
  );
};

export default Refercode;
