import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Skeletion = () => {
  return (
    <div>
      <SkeletonTheme baseColor="#064b4d">
        <div className="p-2">
          <div
            style={{ border: "2px solid #064b4d", borderRadius: "5px" }}
            className="skeletion-new p-3"
          >
            <div className="d-flex mb-1">
              <Skeleton
                circle
                height={12}
                width={12}
                style={{ marginRight: "10px" }}
              />
              <Skeleton height={12} width={120} />{" "}
            </div>
            <Skeleton height={12} width={"100%"} />{" "}
            <Skeleton height={12} width={"70%"} />
          </div>
        </div>
      </SkeletonTheme>{" "}
      <SkeletonTheme baseColor="#064b4d">
        <div className="p-2">
          <div
            style={{ border: "2px solid #064b4d", borderRadius: "5px" }}
            className="skeletion-new p-3"
          >
            <div className="d-flex mb-1">
              <Skeleton
                circle
                height={12}
                width={12}
                style={{ marginRight: "10px" }}
              />
              <Skeleton height={12} width={120} />{" "}
            </div>
            <Skeleton height={12} width={"100%"} />{" "}
            <Skeleton height={12} width={"70%"} />
          </div>
        </div>
      </SkeletonTheme>{" "}
      <SkeletonTheme baseColor="#064b4d">
        <div className="p-2">
          <div
            style={{ border: "2px solid #064b4d", borderRadius: "5px" }}
            className="skeletion-new p-3"
          >
            <div className="d-flex mb-1">
              <Skeleton
                circle
                height={12}
                width={12}
                style={{ marginRight: "10px" }}
              />
              <Skeleton height={12} width={120} />{" "}
            </div>
            <Skeleton height={12} width={"100%"} />{" "}
            <Skeleton height={12} width={"70%"} />
          </div>
        </div>
      </SkeletonTheme>{" "}
    </div>
  );
};

export default Skeletion;
