import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "react-bootstrap";
import { FaArrowsAltV } from "react-icons/fa";
import Slider from "react-slick";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { isEmpty, isNumber } from "lodash";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import { VscGraph } from "react-icons/vsc";
import { priceFormat } from "../Utils/constants";
import useScroll from "./Scroll";
import { useNavigate } from "react-router-dom";
import { AiOutlineStar } from "react-icons/ai";
const FancyBet = ({
  handleShowInfo,
  data,
  user,
  header,
  handelChangeFancyHeader,
  fancyHeaderKey,
  detail,
  profileData,
  setLimit,
  setShowPosition,
  scrollHeight,
  position,
}) => {
  const naviagte = useNavigate();
  const { setShowBetDialog, setBetSlipObject, betSlipObject, showBetDialog } =
    useContext(BetSlipContext);
  var settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
  };
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({});
  useEffect(() => {
    let obj = profileData?.matchSetting?.find(
      (res) =>
        res?.type == "fancy" &&
        (profileData?.arrayCheck == "details"
          ? true
          : res?.sportType == detail?.gameType)
    );
    if (!isEmpty(obj)) {
      setLocalLimit(obj);
    } else {
      setLocalLimit({
        maxAmount: 1000,
        maxProfit: 10000,
        minAmount: 1,
        oddsLimit: 10,
      });
    }
  }, []);

  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setScrollId(index);
        setShowBetDialog(true);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: team,
          betType: type,
          playType: "fancy",
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId: detail?.marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  const [updatedArray, setUpdatedArray] = useState([]);
  useEffect(() => {
    setUpdatedArray(
      data?.filter((res) => {
        return fancyHeaderKey == "All"
          ? true
          : res?.categoryType == fancyHeaderKey
          ? res?.categoryType == fancyHeaderKey
          : true;
      })
    );
  }, [data, fancyHeaderKey]);

  return (
    <>
      <div className="fancy-tab-header sports-tab-panel py-1 px-3 fancy-sports-inner-tab position-relative">
        <AiOutlineStar size={20} className="mr-2" />
        {/* <div className="fave bg-sta/r-sprit-black animate position-absolute start-0"></div> */}
        <div className="w-100">
          <Slider {...settings}>
            {header?.map((res) => {
              return (
                <div
                  key={res?.type}
                  onClick={() => handelChangeFancyHeader(res?.type)}
                >
                  <h3 className={res?.type == fancyHeaderKey ? "active" : ""}>
                    {res?.name}
                  </h3>
                </div>
              );
            })}
          </Slider>
        </div>
        <Button
          className="position-absolute end-0 p-1 top-0 info-btn border-0"
          onClick={() => handleShowInfo()}
        >
          <BsFillInfoCircleFill />
        </Button>
      </div>

      <div className="match-odd-table bg-white p-3 py-1 px-0 mobile-match">
        <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
          <span className="text-center w-100 text-white">No</span>
          <span className="text-center w-100 text-white">Yes</span>
        </div>

        <ul className="mb-2 rounded position-relative p-0" style={{minHeight:`70vh`}}>
          {updatedArray?.length > 0 ? (
            updatedArray?.map((item, index) => {
              if (
                item?.odds?.ms == 4 ||
                item?.odds?.rt?.length == 0 ||
                item?.odds?.ms == 2 ||
                isEmpty(item?.odds?.rt)
              ) {
                return null;
              } else {
                let newPosition = "";
                if (betSlipObject?.teamName === item?.name && showBetDialog) {
                  if (betSlipObject?.object?.ib) {
                    if (Math.abs(item?.position) > 0) {
                      let backLoss =
                        Number(betSlipObject?.bidPrice) +
                        Number(item?.position);
                      newPosition = backLoss;
                    } else {
                      let backLoss = Number(betSlipObject?.bidPrice);
                      newPosition = backLoss;
                    }
                  } else {
                    if (Math.abs(item?.position) > 0) {
                      let backProfit =
                        (betSlipObject?.bidPrice * betSlipObject?.object?.pt) /
                        100;
                      newPosition = Number(backProfit) + Number(item.position);
                    } else {
                      let backProfit =
                        (betSlipObject?.bidPrice * betSlipObject?.object?.pt) /
                        100;
                      newPosition = backProfit;
                    }
                  }
                }
                let condition =
                  betSlipObject?.teamName === item?.name &&
                  Math.abs(newPosition) >= 0;
                let oldPosition = !isEmpty(
                  position?.find((res) => {
                    return res?.selectionId == item?.selectionId;
                  })
                )
                  ? position?.find((res) => {
                      return res?.selectionId == item?.selectionId;
                    })?.position
                  : "";
                return (
                  <>
                    <li
                      style={{ scrollMarginTop: scrollHeight }}
                      ref={index == scrollId ? myRef : null}
                      className="position-relative"
                    >
                      <h2>
                        {item?.name}{" "}
                        {/* {(Math?.abs(item?.position) > 0 || condition) && (
                          <span className={`d-block py-1 ${"team-red-score"}`}>
                            {Math?.abs(item?.position) > 0
                              ? `( ${Math?.abs(item?.position).toFixed(2)} )`
                              : ""}
                            {betSlipObject?.teamName === item?.name &&
                              Math.abs(newPosition) > 0 && (
                                <span>
                                  {" "}
                                  {">"} {Math.abs(newPosition).toFixed(2)}
                                </span>
                              )}
                          </span>
                        )} */}
                        {(isNumber(oldPosition) || condition) && (
                          <span className={`d-block py-1 ${"team-red-score"}`}>
                            {isNumber(oldPosition)
                              ? `( ${Math?.abs(item?.position).toFixed(2)} )`
                              : ""}
                            {betSlipObject?.teamName === item?.name &&
                              Math.abs(newPosition) >= 0 && (
                                <span>
                                  {" "}
                                  {">"}{" "}
                                  {`( ${Math.abs(newPosition).toFixed(2)} )`}
                                </span>
                              )}
                          </span>
                        )}
                      </h2>
                      <div className="lay-back-table f-od">
                        <div
                          title={
                            item?.odds?.rt?.length > 0
                              ? item?.odds?.rt[0]?.rt
                              : ""
                          }
                          id={"lay_odds_fancy_" + index}
                          className={`lay ${
                            Number(
                              document.getElementById("lay_odds_fancy_" + index)
                                ?.title
                            ) !== item?.odds?.rt[0]?.rt
                              ? "spark-lay"
                              : ""
                          }   ${
                            betSlipObject?.teamName == item?.name &&
                            betSlipObject?.odds == item?.odds?.rt[0]?.rt &&
                            showBetDialog &&
                            betSlipObject?.betType == "No"
                              ? "lay-active"
                              : ""
                          }`}
                          onClick={() =>
                            handelChange(
                              item?.odds?.rt[0],
                              "No",
                              item?.name,
                              item,
                              index
                            )
                          }
                        >
                          <strong>{item?.odds?.rt[0]?.rt}</strong>
                          <span className="d-block">
                            {item?.odds?.rt[0]?.pt}
                          </span>
                        </div>
                        <div
                          title={
                            item?.odds?.rt?.length > 0
                              ? item?.odds?.rt[1]?.rt
                              : ""
                          }
                          id={"back_odds_fancy_" + index}
                          className={`back ${
                            Number(
                              document.getElementById(
                                "back_odds_fancy_" + index
                              )?.title
                            ) !== item?.odds?.rt[1]?.rt
                              ? "spark-back"
                              : ""
                          } 
                      ${
                        betSlipObject?.teamName == item?.name &&
                        betSlipObject?.odds == item?.odds?.rt[1]?.rt &&
                        showBetDialog &&
                        betSlipObject?.betType == "Yes"
                          ? "back-active"
                          : ""
                      }
                      `}
                          onClick={() =>
                            handelChange(
                              item?.odds?.rt[1],
                              "Yes",
                              item?.name,
                              item,
                              index
                            )
                          }
                        >
                          <strong>{item?.odds?.rt[1]?.rt}</strong>
                          <span className="d-block">
                            {item?.odds?.rt[1]?.pt}
                          </span>
                        </div>
                      </div>
                      {(item?.odds?.ms == 9 ||
                        item?.odds?.ms == 2 ||
                        item?.odds?.ms == 3 ||
                        item?.odds?.ms == 4) && (
                        <div class="overlay-match">
                          {item?.odds?.ms == 9
                            ? "Ball Running"
                            : item?.odds?.ms == 2
                            ? "In Active"
                            : item?.odds?.ms == 3
                            ? "Suspended"
                            : item?.odds?.ms == 4
                            ? "Closed"
                            : ""}
                        </div>
                      )}
                    </li>
                    <div className="market-depth-sec d-flex justify-content-end py-1">
                      {/* <div className="d-flex align-items-center min-max ">
                        <FaArrowsAltV />
                        <span>min/max</span>
                        <strong>
                          {localLimit?.minAmount || 1}/
                          {priceFormat(localLimit?.maxAmount) || 100}
                        </strong>
                      </div> */}
                       {isNumber(oldPosition) && (
                        <div
                          onClick={() =>
                            setShowPosition({
                              status: true,
                              object: { ...item, match: detail?.eventName },
                            })
                          }
                          className="book-button"
                        >
                          <VscGraph className="icon" />
                          <span data-testid="books-openBtn" class="text-11">
                            Book
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                );
              }
            })
          ) : (
            <div style={{textAlign:`center`,color:`#fff`}}>No Record Found</div>
          )}
        </ul>
      </div>
    </>
  );
};

export default FancyBet;
