import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import BackLay from "./BackLay";
import AuthContext from "../context/AuthContext";
import { priceFormat } from "../Utils/constants";
import growth from "../assets/images/growth.svg";
import BattingIframe from "./BattingIframe";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SkeletonTheme } from "react-loading-skeleton";
import LiveMatchTracker from "../pages/Score/ScoreBoard";
import Loader from "./Loader";
import BackLayHome from "./BackLayHome";
import SportAccordianInner from "./SportAccordianInner";
import { FaRegStar } from "react-icons/fa";
const ByTimeSports = ({
  item,
  index,
  keyTime,
  activeKeys,
  handleSelect,
  type,
  score,
  activeFav,
  setActiveFav,
  odds,
  multiOddsPremium,
  matchOdds,
}) => {
  const { user, parly } = useContext(AuthContext);
  const [timmer, setTimer] = useState(false);
  const [showScore, setShowScore] = useState([]);
  const addToFavioute = async (
    id,
    favoriteType,
    favoriteMarketId,
    eventType
  ) => {
    const { status, data: response_users } = await apiGet(
      apiPath.addToFavourite +
        `?eventId=${id}&favoriteType=${favoriteType}&favoriteMarketId=${favoriteMarketId}&eventType=${eventType}`
    );

    if (response_users?.success) {
      if (activeFav?.includes(id)) {
        setActiveFav((prev) => {
          return prev.filter((item) => {
            return item !== id;
          });
        });
      } else {
        setActiveFav((prev) => {
          return [...prev, id];
        });
      }
    }
  };
  const navigate = useNavigate();
  return (
    <div className="sport-accord-body" style={{ marginBottom: "1px" }}>
      <div className="position-relative">
        <div
          style={{ background: "#064b4d"}}
          className={
            "d-flex justify-content-between align-items-center  bg-primary-odds p-1"
          }
        >
          <div
            className={`fave  bg-star-sprit-black  position-absolute start-0 ${
              activeFav?.includes(item?.eventId) ? "animate" : ""
            }`}
            style={{
              marginLeft: "-20px !important",
              marginTop: "-16px",
            }}
            onClick={() => {
              if (isEmpty(user)) {
                navigate("/login");
              } else {
                if (item?.hasMatchOdds) {
                  addToFavioute(
                    item?.eventId,
                    item?.marketId,
                    "Match Odds",
                    item?.eventType
                  );
                }
              }
            }}
          ></div>

          <div
            style={{ width: "90%", color: "white",marginLeft:"4px",padding:"3px" }}
            onClick={() =>
              navigate(`/full-market/${item?.marketId}/${item?.eventId}`)
            }
            className={"ps-4 mt-0"}
          >
            <span style={{ fontWeight: "600", fontSize: "12px" }}>
              {item?.eventName}
            </span>{" "}
            <div
              className="half-table"
              style={{
                display: "flex",
                flex: "1",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
                paddingTop:"2px"
              }}
            >
              <span
                className={`in-play ${
                  keyTime == "in_play"
                    ? "in-play"
                    : keyTime == "active"
                    ? keyTime
                    : "tommorow"
                }`}
              >
                {keyTime == "in_play"
                  ? "In-play"
                  : keyTime == "active"
                  ? `${obj?.matchDateTime(item?.eventDateTime, "same")}`
                  : obj?.dateFormat(item?.eventDateTime)}
              </span>{" "}
              {matchOdds?.totalMatched > 0 && (
                <>
                  <span
                    className="text-left"
                    style={{ fontSize: "10px", marginLeft: "4px" }}
                  >
                    Matched
                  </span>
                  <strong style={{ fontSize: "10px", marginLeft: "3px" }}>
                    {matchOdds?.totalMatched > 0 &&
                      `${priceFormat(matchOdds?.totalMatched)}`}
                  </strong>{" "}
                </>
              )}
            </div>
          </div>

          <div
            className="d-flex align-items-center"
            style={{ whiteSpace: `nowrap`, gap: `7px` }}
          >
            {score?.length > 0 &&
            !isEmpty(
              score?.find((res) => {
                return Number(res?.eventId) == Number(item?.eventId);
              })
            ) ? (
              <div
                className="counter"
                style={{
                  fontSize: "11px",
                  fontWeight: "800",
                  color: "#ffb80c",
                }}
              >
                {
                  score?.find((res) => {
                    return Number(res?.eventId) == Number(item?.eventId);
                  })?.score
                }
              </div>
            ) : item?.outcome && item?.outcome != undefined ? (
              <div
                className="counter"
                style={{
                  fontSize: "11px",
                  fontWeight: "800",
                  color: "#ffb80c",
                }}
              >
                {item?.outcome}
              </div>
            ) : (
              ""
            )}

            <Button className="bg-transparent text-white border-0 fs-4 px-2 pt-0 pe-0">
              <FaAngleRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ByTimeSports;
