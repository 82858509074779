import React, { useContext } from "react";
import HeaderNew from "../HeaderNew";
import FooterNew from "../FooterNew";
import Footer from "../Footer";
import Loader from "../Loader";
import FooterSection from "../FooterSection";
import { useLocation } from "react-router-dom";
import PlaysBet from "../PlaysBet";
import AuthContext from "../../context/AuthContext";
import BetSlipContext from "../../context/BetSlipContext";
import Message from "../Message";
import BetSlipParly from "../BetSlipParly";

const LayoutNew = ({ children }) => {
  const location = useLocation();
  const { showBetDialog, message } = useContext(BetSlipContext);
  const { showParlyBet, parly } = useContext(AuthContext);
  return (
    <div className="position-relative">
      <HeaderNew />
      <div className="home-desktop-container">
        {children}
        <Loader />
        {showBetDialog && <PlaysBet />}
        {message?.status && <Message />}
        <Footer />
      </div>
      {location?.pathname == "/" || location?.pathname == "/sports" ? (
        <FooterSection />
      ) : (
        ""
      )}

      {showParlyBet && parly && <BetSlipParly />}
      <FooterNew />
    </div>
  );
};

export default LayoutNew;
