import React, { useContext } from "react";
import AuthProvider from "../context/AuthContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const GameCard = ({ item, cartType, res }) => {
  let { launchEGTCasino, user, launchCasino,amounutRefresh,doLoginAndLaunchJetxCasino , handleShowLoginNotification} = useContext(AuthProvider);
  const navigate = useNavigate();
  return (
    <div
      className="game-card position-relative"
      onClick={() => {
        if (!isEmpty(user)) {
          if (res?.gameTypeCheck == "sports") {
            navigate("/")
            }
            else if (res?.platForm !== "" && res?.gameType !== "" && res?.casinoType !== "") {
            
              if (!isEmpty(res?.table)) {
                amounutRefresh();
                setTimeout(() => {
                  launchCasino({
                    platForm: res?.platForm,
                    gameType: res?.gameType,
                    casinoType: res?.casinoType,
                    table: res?.table,
                  });
                }, 800);
              } else {
                amounutRefresh();
                setTimeout(() => {
                  launchCasino({
                    platForm: res?.platForm,
                    gameType: res?.gameType,
                    casinoType: res?.casinoType,
                  });
                }, 800);
              }
            }
            else if ( res?.gameId !== "" ) {
              
              amounutRefresh();
                setTimeout(() => {
                  doLoginAndLaunchJetxCasino({
                    GameCategory: res?.GameCategory,
                    game_name: res?.game_name,
                    gameId: res?.gameId,
                  });
                }, 800);
              
            }
            else{
              
                amounutRefresh();
                setTimeout(() => {
                  launchCasino({
                    platForm: res?.platForm,
                    gameType: res?.gameType,
                    casinoType: res?.casinoType,
                  });
                }, 800);
              
            }
          
        } else {
          handleShowLoginNotification();
        }
      }}
    >
      {cartType && (
        <div className="table-data">
          <span className="">Table1</span>
        </div>
      )}
      {/* <img loading="lazy" src={res?.img} alt="" /> */}
      <LazyLoadImage
        alt={res?.img}
        // height={image.height}
        effect="opacity"
        src={res?.img} // use normal <img> attributes as props
        // width={image.width}
      />
      <span className="casinon">{res?.name}</span>
    </div>
  );
};

export default GameCard;
