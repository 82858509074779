import React, { useContext,useState } from "react";
import { Link, useNavigate,NavLink } from "react-router-dom";
import logo from "../assets/images/logo-bajifair.png";
import appdownloadicon from "../assets/images/header-appdownload-icon.svg"
import headerserviceicon from "../assets/images/header-service-icon.svg"
import { isEmpty, startCase } from "lodash";
import AuthContext from "../context/AuthContext";
import {
  MdArrowBackIosNew,
  MdKeyboardArrowLeft,
  MdOutlineSubject,
} from "react-icons/md";
import MenuSidebar from "./MenuSidebar";
import MenuSidebarNew from "./MenuSidebarNew";
import LanguagePopup from "./LanguagePopup";
import LoginNotification from "./LoginNotification";

import { HiOutlineRefresh } from "react-icons/hi";
import { useTranslation } from "react-i18next";
//  import bajifairapp from "../assets/images/bajifaircomapp.apk";
import FlagIcon from "../assets/imagesNew/united-states.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavDropdown,
  Nav,
  Button,
  Offcanvas,
  Form,
 
} from "react-bootstrap";
import DepositIcon from "../assets/icons/icon-deposit.svg";
import iconWithdrawal from "../assets/icons/icon-withdrawal.svg";
import iconBetReacord from "../assets/icons/icon-bet-records.svg";
import iconTurnover from "../assets/icons/icon-turnover.svg";
import iconRecords from "../assets/icons/icon-records.svg";
import iconProfile from "../assets/icons/icon-info.svg";
import iconLogout from "../assets/icons/icon-login.svg";


const HeaderNew = () => {
  const [expanded, setExpanded] = useState(false);
  const {
    user,
    handleShowLanguage,
    showLanguage,
    handleShowSidebar,
    showSidebar,
    amounutRefresh,
    refreshAmountLoader,
    userCoins,
    loginUser,
    lang, setEn, setBr,
    handleShowLoginNotification,
    setShowLoginNotification,
    showLoginNotification,
    logoutUser
  } = useContext(AuthContext);
  const navigate = useNavigate()
  const { t } = useTranslation();

  return (
    <>
      <div id={'header'} className="login-header-new">
        <div className="login-reg-wrapper">
        <button
                className="bg-transparent border-0 text-white"
                onClick={() => handleShowSidebar()}
              >
                <MdOutlineSubject className="fs-4" />
              </button>
          <figure onClick={() => navigate('/')}>
       
            <img
           
            src={logo}
            alt=""
          />
          {/* } */}
          </figure>
          {isEmpty(user) ? (
            <figcaption>
              <Link to="https://khelmu.com" className="login-btn">{t("Sign_up")}</Link>
            <Link to="/login" className="signup-btn">{t("Login")}</Link> 
              
            </figcaption>
          ) : (
        
               
           
            <>
            
              {/* <div className="d-flex align-items-lg-start for-ref after-login w-100">
                {refreshAmountLoader ? (
                  <div class="loader-outer">
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: "0s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".1s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".2s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".3s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".4s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".5s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".6s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".7s" }}
                    ></span>
                  </div>
                ) : (
                  <div
                    
                    className="header-outer"
                  >
                    <div className="paise" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: "10px" }}>
                        <span>{user?.user?.username}</span>
                        <div className="d-flex align-items-center">
                          <strong className="header-font-size">
                            BDT{" "}
                            <span style={{ marginLeft: "3px", color: "white" }}>
                              {userCoins?.balance?.toFixed()}
                            </span>
                          </strong>
                          <strong className="header-font-size">
                            Exp{" "}
                            <span
                              className="text-white"
                              style={{ marginLeft: "3px", fontWeight: "700" }}
                            >
                              ({userCoins?.exp?.toFixed()})
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div onClick={amounutRefresh}>
                        <HiOutlineRefresh
                          size={20}
                          className={refreshAmountLoader ? "refresh-icon" : ""}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div> */}
              {/* <button
                className="bg-transparent border-0 text-white"
                onClick={() => handleShowSidebar()}
              >
                <MdOutlineSubject className="fs-4" />
              </button> */}
            </>
            // <div
            //   style={{
            //     display: "flex",
            //     justifyContent: "space-between",
            //     alignItems: "center",
            //     width: "100%",
            //   }}
            // >
            //   {" "}
            //   <figcaption>+{user?.user?.username}</figcaption>
            //   <button
            //     className="bg-transparent border-0 text-white"
            //     onClick={() => handleShowSidebar()}
            //   >
            //     <MdOutlineSubject className="fs-4" />
            //   </button>
            // </div>
          )}
          <div className="h-amt">
          {!isEmpty(user) &&
          <>
             <div  class="cricket-money ng-star-inserted" >
             <div onClick={amounutRefresh}>
                        <HiOutlineRefresh
                          size={18}
                          className={refreshAmountLoader ? "refresh-icon" : ""}
                        />
                </div> 
                <div  class="">
                </div> 
               
                  USD<span > {Number(userCoins?.balance).toFixed(2)}</span>
              </div>
              <div  class="exp ng-star-inserted" > EXP <span > ({Number(userCoins?.exp).toFixed(2)})</span>
              </div>
              </>
              }
             
              </div>
              
          {/* <div className="header-right-btn-group">
          
            <a href={"#"}  download="bajifair" target='_blank'><img src={appdownloadicon} /><span>App</span></a>
            <a href="#"><img src={headerserviceicon}/><span >Help</span></a>
            </div> */}
        </div>
        {showSidebar && (
          <MenuSidebarNew
            cancelMenu={handleShowSidebar}
            showSidebar={showSidebar}
          />
        )}
      {showLanguage && (
          <LanguagePopup
            cancelMenu={handleShowLanguage}
            showSidebar={showLanguage}
          />
        )}
          {showLoginNotification && (
          <LoginNotification
            cancelMenu={handleShowLoginNotification}
            showSidebar={showLoginNotification}
          />
        )}
         
        {showSidebar ? (
          <div className="overlay" onClick={() => handleShowSidebar()}></div>
        ):""}
          {showLanguage? (
          <div className="overlay" onClick={() => handleShowLanguage()}></div>
        ):""}
         {showLoginNotification ? (
          <div className="overlay" onClick={() => handleShowLoginNotification()}></div>
        ):""}
         
      </div>
    </>
  );
};

export default HeaderNew;
